import React from "react"
import Header from "./header"
import SupportBar from "./supportBar"
import Footer from "./footer"
import Helmet from "./helmet"

export default ({ children }) => (
  <>
    <Helmet />
    <SupportBar />
    <Header top={true} bottom={true} />
    {children}
    <Footer />

    <div className="back-to-top">
      <i className="fas fa-angle-up" />
    </div>
  </>
)
