import React from "react"
import { Link } from "gatsby"
import * as constants from "../constants"

export default () => (
  <nav className="navbar-area">
    <div className="container">
      <div className="row navbar-bg">
        <div className="col-md-9 col-sm-6 col-xs-3">
          <div className="responsive-menu" />
          <ul id="primary-menu">
            <li className="active">
              <Link to="/">Home</Link>
            </li>
            <li>
              <a href="#antenne">Antenne e Sky</a>
            </li>
            <li>
              <a href="#assistenza">Assistenza tecnica</a>
            </li>
            <li>
              <a href="#marchi">Marchi</a>
            </li>
            <li>
              <a href="#team">Team</a>
            </li>
            <li>
              <a href="#timetable">Orari</a>
            </li>
            <li>
              <a href="#where">Contattaci</a>
            </li>
          </ul>
        </div>
        <div className="col-md-3 col-sm-6 col-xs-9">
          <div className="navbar-right-area">
            <ul>
              <li>
                <a href={constants.FB_PAGE} target="_blank">
                  <i className="fab fa-facebook-f" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </nav>
)
