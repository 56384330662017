import React from "react"
import * as constants from "../constants"
import logo from "../../res/img/logo.png"

export default email => (
  <div className="support-bar">
    <div className="container">
      <div className="row">
        <div className="col-md-3 col-sm-3 col-xs-12">
          <a href="index.html" className="logo">
            <img src={logo} alt="logo" />
          </a>
        </div>
        <div className="col-md-9 col-sm-9 col-xs-12">
          <div className="support-right">
            <div className="single-support-box">
              <div className="icon">
                <i className="fas fa-phone" />
              </div>
              <div className="content">
                <a href={`tel:${constants.TELEPHONE1}`}>
                  {constants.TELEPHONE1}
                </a>
                <a href={`tel:${constants.TELEPHONE_MARZIO}`}>
                  {constants.TELEPHONE_MARZIO}
                </a>
                <a href={`tel:${constants.TELEPHONE_NICOLA}`}>
                  {constants.TELEPHONE_NICOLA}
                </a>
              </div>
            </div>
            <div className="single-support-box">
              <div className="icon">
                <i className="fas fa-map-marker-alt" />
              </div>
              <div className="content">
                <a>
                  {constants.ADDRESS1} {constants.ADDRESS2}
                </a>
                <a href={`mailto:${constants.EMAIL}`}>{constants.EMAIL}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)
