export const NAME = "Baccinelli Marzio & C."
export const FULLNAME = "Baccinelli Marzio & C. s.n.c."
export const EMAIL = "info@baccinelli.com"
export const TELEPHONE1 = "+39 030 2006411"
export const TELEPHONE_MARZIO = "+39 329 6722007 (Marzio)"
export const TELEPHONE_NICOLA = "+39 329 4858419 (Nicola)"
export const VATCODE = "02872630179"
export const ADDRESS1 = "Via Andrea del Verrocchio, 146"
export const ADDRESS2 = "25124 Brescia"
export const GMAPS_API_KEY = "AIzaSyBvqlb9C0vKFOYO08NzF5Qy5OuiXNbmymg"
export const FB_PAGE =
  "https://www.facebook.com/Baccinelli-Marzio-c-snc-682039311906388/"
