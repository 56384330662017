import React, { Component } from "react"
import * as constants from "../constants"

class Where extends Component {
  render() {
    return (
      <section className="contact-area" id="where">
        <div className="row">
          <div className="col-md-6 col-md-offset-3 text-center">
            <div className="section-title">
              <span className="subtitle">la nostra</span>
              <h2>Sede</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-md-offset-3 text-center">
            <ul>
              <li>
                <i className="fas fa-home fa-2x" />
                <br />
                {constants.ADDRESS1} - {constants.ADDRESS2}
                <br />
                <br />
                <br />
                <br />
              </li>
            </ul>
          </div>
        </div>
      </section>
    )
  }
}

export default Where
