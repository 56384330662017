import React from "react"
import * as constants from "../constants"

export default () => (
  <>
    <section className="pricing-table-area" id="timetable">
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-md-offset-3 text-center">
            <div className="section-title">
              <span className="subtitle">i nostri</span>
              <h2>Orari</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-offset-1 col-md-2 remove-col-padding col-sm-6">
            <div className="single-price-plan blue">
              <div className="body">
                <span className="name">Lunedì</span>
                <ul>
                  <li>09:00 - 12:00</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-md-2 remove-col-padding col-sm-6">
            <div className="single-price-plan yellow">
              <div className="body">
                <span className="name">Martedì</span>
                <ul>
                  <li>09:00 - 12:00</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-md-2 remove-col-padding col-sm-6">
            <div className="single-price-plan red">
              <div className="body">
                <span className="name">Mercoledì</span>
                <ul>
                  <li>09:00 - 12:00</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-md-2 remove-col-padding col-sm-6">
            <div className="single-price-plan green">
              <div className="body">
                <span className="name">Giovedì</span>
                <ul>
                  <li>09:00 - 12:00</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-md-2 remove-col-padding col-sm-6">
            <div className="single-price-plan yellow-2">
              <div className="body">
                <span className="name">Venerdì</span>
                <ul>
                  <li>09:00 - 12:00</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="pricing-table-area" id="contact">
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-md-offset-3 text-center">
            <div className="section-title">
              <span className="subtitle">i nostri</span>
              <h2>Contatti</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-md-offset-3 text-center">
            <ul>
              <li>
                <i className="fas fa-phone fa-2x" />
                <br />
                {constants.TELEPHONE1} - {constants.TELEPHONE_MARZIO} - {constants.TELEPHONE_NICOLA}
                <br />
                <br />
              </li>
              <li>
                <i className="fas fa-envelope fa-2x" />
                <br />
                <a href={`mailto:${constants.EMAIL}`}>{constants.EMAIL}</a>
                <br />
                <br />
              </li>
            </ul>

            <div className="row">
              <div className="col-md-6 col-md-offset-3 text-center">
                <a href={`mailto:${constants.EMAIL}`} className="boxed-btn">
                  scrivici
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
)
