import React from "react"
import * as constants from "../constants"
import img01 from "../../res/img/payment/01.jpg"
import footerlogo from "../../res/img/footer-logo.png"

export default () => (
  <footer className="footer-area">
    <div className="footer-inner-wrapper">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            {/*<div className="footer-top">
                <ul id="footer-payment-logo">
                    <li>
                        <a href="#"><img src={img01} alt="payment logo" /></a>
                    </li>
                    <li>
                        <a href="#"><img src={img01} alt="payment logo" /></a>
                    </li>
                    <li>
                        <a href="#"><img src={img01} alt="payment logo" /></a>
                    </li>
                    <li>
                        <a href="#"><img src={img01} alt="payment logo" /></a>
                    </li>
                    <li>
                        <a href="#"><img src={img01} alt="payment logo" /></a>
                    </li>
                    <li>
                        <a href="#"><img src={img01} alt="payment logo" /></a>
                    </li>
                </ul>
            </div>*/}
            <div className="footer-bottom">
              <div className="row">
                <div className="col-md-10">
                  <div className="widget about">
                    <div className="widget-body">
                      <a href="index.html" className="footer-logo">
                        <img src={footerlogo} alt="footer logo" />
                      </a>
                      <p>
                        Antenne, Decoder, offerte e attivazioni Sky. Laboratorio
                        di assistenza tecnica Akg, Alpine, Harmankardon,
                        Infinity, Jbl, Kenwood, Onkyo, Sharp. Installazioni
                        sistemi audio home theatre completi.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="widget contact">
                    <div className="widget-title">
                      <h4>Contattaci</h4>
                    </div>
                    <div className="widget-body">
                      <ul>
                        <li>
                          <i className="fas fa-home" /> {constants.ADDRESS1}{" "}
                          <br /> {constants.ADDRESS2}
                        </li>
                        <li>
                          <i className="fas fa-phone" /> {constants.TELEPHONE1}{" "}
                          <br /> {constants.TELEPHONE_MARZIO}
                          <br /> {constants.TELEPHONE_NICOLA}
                        </li>
                        <li>
                          <i className="fas fa-envelope" /> {constants.EMAIL}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="copyright-area">
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center">
            {constants.FULLNAME} - P.I. {constants.VATCODE} -{" "}
            {constants.ADDRESS1} - {constants.ADDRESS2}
          </div>
        </div>
      </div>
    </div>
  </footer>
)
