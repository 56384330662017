import React from "react"
import intro from "../../res/img/intro-image.png"

export default () => (
  <section className="intro-video-area intro-bg" id="team">
    <div className="container">
      <div className="row">
        <div className="col-md-6">
          <div className="left-content">
            <a className="video-play-btn mfp-iframe">
              <i className="fas fa-users" /> Staff
            </a>
            <h2>
              <span>Esperienza</span> al tuo servizio
            </h2>
            <p>
              La serietà e la professionalità che Marzio e Nicola hanno da sempre voluto privilegiare è garanzia di
              un servizio di qualità, certificato dai frequenti aggiornamenti tecnici dalle case madri, per soddisfare le esigenze dei propri clienti.
            </p>
          </div>
        </div>
        <div className="col-md-6">
          <div className="right-content">
            <div className="thumb">
              <img src={intro} alt="intro image" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
)
