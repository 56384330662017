import React from "react"
import { Helmet } from "react-helmet"
import LayoutHome from "../components/layoutHome"
import Antenne from "../components/antenne"
import Assistenza from "../components/assistenza"
import Brand from "../components/brand"
import Features from "../components/features"
import Team from "../components/team"
import Hours from "../components/hours"
import Where from "../components/where"

export default () => (
  <LayoutHome>
    <Antenne />
    <Assistenza />
    <Brand />
    <Features />
    <Team />
    <Hours />
    <Where />
  </LayoutHome>
)
