import React from "react"

export default () => (
  <section className="offer-area" id="area">
    <div className="container">
      <div className="row">
        <div className="col-md-6 col-md-offset-3 text-center">
          <div className="section-title">
            <span className="subtitle">i nostri</span>
            <h2>Punti di forza</h2>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-3 col-sm-6">
          <div className="single-offer-box">
            <div className="icon">
              <i className="far fa-object-group" />
            </div>
            <div className="content">
              <h5>Esperienza</h5>
              <p>Grande esperienza nel settore dell'elettronica di consumo</p>
            </div>
          </div>
        </div>
        <div className="col-md-3 col-sm-6">
          <div className="single-offer-box blue">
            <div className="icon">
              <i className="fas fa-tools" />
            </div>
            <div className="content">
              <h5>Centro assistenza</h5>
              <p>Centro autorizzato per i marchi principali sul mercato.</p>
            </div>
          </div>
        </div>
        <div className="col-md-3 col-sm-6">
          <div className="single-offer-box yellow">
            <div className="icon">
              <i className="fas fa-globe" />
            </div>
            <div className="content">
              <h5>Servizio a 360°</h5>
              <p>Progettazione e installazione di impianti audio e video.</p>
            </div>
          </div>
        </div>
        <div className="col-md-3 col-sm-6">
          <div className="single-offer-box red">
            <div className="icon">
              <i className="fas fa-broadcast-tower" />
            </div>
            <div className="content">
              <h5>Decoder TV</h5>
              <p>
                Vendita e installazione decoder satellitari e digitale
                terrestre.
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-3 col-sm-6">
          <div className="single-offer-box purple">
            <div className="icon">
              <i className="fas fa-satellite-dish" />
            </div>
            <div className="content">
              <h5>Impianti su misura</h5>
              <p>Realizziamo impianti singoli e centralizzati.</p>
            </div>
          </div>
        </div>
        <div className="col-md-3 col-sm-6">
          <div className="single-offer-box yellow-2">
            <div className="icon">
              <i className="fas fa-film" />
            </div>
            <div className="content">
              <h5>Home theatre</h5>
              <p>Punto vendita televisori oled, lcd e home theatre</p>
            </div>
          </div>
        </div>
        <div className="col-md-3 col-sm-6">
          <div className="single-offer-box lite-blue">
            <div className="icon">
              <i className="fas fa-clone" />
            </div>
            <div className="content">
              <h5>Multimarchio</h5>
              <p>Riparazioni multimarchio con servizio di ritiro e consegna.</p>
            </div>
          </div>
        </div>
        <div className="col-md-3 col-sm-6">
          <div className="single-offer-box lite-green">
            <div className="icon">
              <i className="fas fa-map-marked-alt" />
            </div>
            <div className="content">
              <h5>Siamo a Brescia</h5>
              <p>
                Operiamo a Brescia e provincia. Aperti dal Lunedì al Sabato.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
)
