import React from "react"
import Navbar from "./navbar"

export default props => (
  <header className="header-area header-area-bg">
    <Navbar />

    {props.top && (
      <div className="container" id="home">
        <div className="row">
          <div className="col-md-10 col-md-offset-1 text-center">
            <div className="header-inner-content">
              <h1>Baccinelli Marzio &amp; C.</h1>
              <p>
                Ci occupiamo di installazioni e assistenza in ambito
                audio &amp; video.
                <br />
                Installatori autorizzati Sky e assistenza autorizzata per i
                principali brand di elettronica di consumo.
              </p>
              <div className="btn-wrapper" id="header-btn">
                <a href="#contact" className="boxed-btn">
                  Contattaci
                </a>
                <a href="#marchi" className="boxed-btn blank">
                  marchi
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    )}

    {props.bottom && (
      <div className="header-bottom" id="home">
        <div className="container">
          <div className="row">
            <div className="col-md-4 remove-col-padding">
              <div className="single-header-bottom-box yellow header-box-bg-1 text-center">
                <div className="icon">
                  <i className="fas fa-tools" />
                </div>
                <div className="content">
                  <a href="#">
                    <h4>Assistenza tecnica</h4>
                  </a>
                  {/*<div className="hover">
                                    <p>Assistenza tecnica specializzata per prodotti audio &amp; video</p>
                                </div>*/}
                </div>
              </div>
            </div>
            <div className="col-md-4 remove-col-padding">
              <div className="single-header-bottom-box gray header-box-bg-2 text-center">
                <div className="icon">
                  <i className="fas fa-satellite-dish" />
                </div>
                <div className="content">
                  <a href="#">
                    <h4>Antenne</h4>
                  </a>
                  <div className="hover">
                    <p>
                      Realizziamo impianti singoli e centralizzati, Satellitari
                      e Digitale Terrestre
                    </p>
                    <a href="#antenne" className="boxed-btn transparent">
                      <i className="fas fa-caret-right" /> Leggi tutto
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 remove-col-padding">
              <div className="single-header-bottom-box yellow header-box-bg-3 text-center">
                <div className="icon">
                  <i className="fas fa-tv" />
                </div>
                <div className="content">
                  <a href="#">
                    <h4>Vendita e Installazione</h4>
                  </a>
                  {/*<div className="hover">
                                    <p>Vendita e installazione TV, Decoder Sat e Digitale Terrestre</p>
                                </div>*/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )}
  </header>
)
