import React from "react"
import sharp from "../../res/img/brands/sharp.jpg"
import intred from "../../res/img/brands/intred.jpg"
import sky from "../../res/img/brands/sky.jpg"

export default () => (
  <section className="service-area" id="marchi">
    <div className="container">
      <div className="row">
        <div className="col-md-6 col-md-offset-3 text-center">
          <div className="section-title">
            <span className="subtitle">
              Assistenza autorizzata e in garanzia per
            </span>
            <h2>Marchi</h2>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-4 col-sm-6">
          <div className="single-service-item">
            <div className="thumb">
              <img src={sharp} alt="Sharp" />
            </div>
            <div className="content">
              <a href="#">
                <h4>Sharp</h4>
              </a>
            </div>
          </div>
        </div>
        <div className="col-md-4 col-sm-6">
          <div className="single-service-item">
            <div className="thumb">
              <img src={intred} alt="Intred" />
            </div>
            <div className="content">
              <a href="#">
                <h4>Intred</h4>
              </a>
            </div>
          </div>
        </div>
        <div className="col-md-4 col-sm-6">
          <div className="single-service-item">
            <div className="thumb">
              <img src={sky} alt="Sky" />
            </div>
            <div className="content">
              <a href="#">
                <h4>Sky</h4>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
)
