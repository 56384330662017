import React from "react"
import image from "../../res/img/antenne.jpg"

export default () => (
  <section className="features-area" id="antenne">
    <div className="container">
      <div className="row">
        <div className="col-md-6">
          <div className="left-content">
            <h2>
              <span>Decoder, impianti e</span> Antenne
            </h2>
            <p>
              Ci proponiamo di dare soluzioni alle domande che spesso non
              trovano risposta. Vi aiuteremo a scegliere l’offerta migliore SKY
              adatta alle Vostre particolari esigenze con un servizio
              trasparente e professionale. Ci occupiamo di portare SKY a casa
              Vostra eseguendo se possibile l’installazione standard della
              parabola e decoder sia eseguendo, se richiesto, un’installazione
              più complessa.
            </p>
            <div className="row">
              <div className="col-md-6 col-sm-6 col-xs-6">
                <ul>
                  <li>
                    <i className="fas fa-check" /> Installatore autorizzato Sky
                  </li>
                  <li>
                    <i className="fas fa-check" /> Installazione parabola e
                    decoder
                  </li>
                </ul>
              </div>
              <div className="col-md-6 col-sm-6 col-xs-6">
                <ul>
                  <li>
                    <i className="fas fa-check" /> Consulenza e assistenza
                  </li>
                  <li>
                    <i className="fas fa-check" /> Impianti singoli e
                    centralizzati
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="right-content">
            <img src={image} alt="features" />
          </div>
        </div>
      </div>
    </div>
  </section>
)
