import React from "react"
import image from "../../res/img/assistenza.jpg"

export default () => (
  <section className="features-area" id="assistenza" style={{ paddingTop: 0 }}>
    <div className="container">
      <div className="row">
        <div className="col-md-6">
          <div className="right-content">
            <img src={image} alt="features" />
          </div>
        </div>
        <div className="col-md-6">
          <div className="left-content">
            <h2>
              <span>Audio e video</span> Assistenza Tecnica
            </h2>
            <p>
              La nostra struttura fornisce un servizio di assistenza tecnica,
              sia verso utenti privati che verso aziende, per tutti quei beni
              dell’elettronica di consumo audio e video che, mai come ora,
              utilizzano sempre più un alto livello tecnologico, rendendo il
              compito della riparazione difficilmente alla portata di tutti.
            </p>
            <div className="row">
              <div className="col-md-6 col-sm-6 col-xs-6">
                <ul>
                  <li>
                    <i className="fas fa-check" /> Assistenza in garanzia
                  </li>
                  <li>
                    <i className="fas fa-check" /> Assistenza all'installazione
                  </li>
                </ul>
              </div>
              <div className="col-md-6 col-sm-6 col-xs-6">
                <ul>
                  <li>
                    <i className="fas fa-check" /> Ritiro e consegna a domicilio
                  </li>
                  <li>
                    <i className="fas fa-check" /> Consulenza e vendita Audio e
                    Video
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
)
